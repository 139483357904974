import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import { graphql } from "gatsby"
import Image from "gatsby-image"
import Layout from "../layout"

import SEO from "../seo"
import styled from "styled-components"

import { Wrapper } from "../../styles/StoreStyles.js"
import RelatedProduct from "./relatedProduct"

import { ProductDetail, Related } from "../../styles/ProductDetailStyles.js"
import {Button} from "../../styles/Button"
import {
  Zoom,
  ProductImageArea,
  ButtonArea,
  FancyButton,
} from "../../styles/TemplatesFolderStyles.js"
import BodyWrapper from "../../templates/BodyWrapper"

export const query = graphql`
  query SINGLE_PRODUCT($id: String!) {
    shopifyProduct(id: { eq: $id }) {
      handle
      title
      description
      variants {
        title
        shopifyId
      }
      images {
        id
        localFile {
          childImageSharp {
            fluid {
              src
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    products: allShopifyCollection(filter: { handle: { eq: "shop" } }) {
      edges {
        node {
          products {
            title
            handle
            images {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const ProductTemplate = ({ data }) => {
  const product = data.shopifyProduct
  const [imgArr] = useState(product.images)
  const [zoom, setZoom] = useState(false)

  const [relatedProducts, setRelatedProducts] = useState([])
  const [imageFocus, setImageFocus] = useState(0)
  const [optionsArr] = useState([0, 2, 3, 4])

  useEffect(() => {
    function getRelatedProducts(products) {
      const arr = products.edges.map(({ node }) => node)
      const filtered = arr[0].products.filter(
        (el) => el.handle !== product.handle
      )

      const randomizedRecommendations = [...filtered]
      while (randomizedRecommendations.length !== 4) {
        const index = Math.floor(Math.random() * filtered.length)
        randomizedRecommendations.splice(index, 1)
      }

      return randomizedRecommendations
    }

    setRelatedProducts(getRelatedProducts(data.products))
  }, [])

  return (
    <Layout>
      <SEO title={product.title} />
      {zoom && (
        <Zoom className="zoom">
          <div>
            <button className="close" onClick={() => setZoom(!zoom)}>
              Exit
            </button>
          </div>
          <img
            src={product.images[imageFocus].localFile.childImageSharp.fluid.src}
            alt={product.title}
          />
          <section className="moreImg">
            {optionsArr.map((el, i) => (
              <ButtonArea key={el} onClick={() => setImageFocus(el)}>
                <FancyButton element={el} imageFocus={imageFocus}></FancyButton>
              </ButtonArea>
            ))}
          </section>
        </Zoom>
      )}
      <div style={{background:"var(--beige)"}}>

      <BodyWrapper>
        <Wrapper bg={"var(--beige)"}>
          <ProductDetail>
            <div className="product-with-btn">
              <ImgContainer>
                <ProductImageArea
                  onClick={() => setZoom(!zoom)}
                  className="productImg-div"
                >
                  <Image
                    fadeIn={true}
                    fluid={
                      product.images[imageFocus].localFile.childImageSharp.fluid
                    }
                    alt={product.title}
                  />
                </ProductImageArea>

                <div className="thumbnail-container">
                  {imgArr.length > 1 &&
                    imgArr.map((el, index) => {
                      if (
                        index === 0 ||
                        index === 3 ||
                        index === 4 ||
                        index === 5
                      )
                        if (imageFocus === index) {
                          return (
                            <img
                              key={index}
                              style={{
                                width: "90px",
                                height: "90px",

                                marginRight: "10px",
                              }}
                              src={el.localFile.childImageSharp.fluid.src}
                            />
                          )
                        } else {
                          return (
                            <img
                              key={index}
                              onClick={() => setImageFocus(index)}
                              style={{
                                width: "60px",
                                height: "60px",
                                marginRight: "10px",
                              }}
                              src={el.localFile.childImageSharp.fluid.src}
                            />
                          )
                        }
                    })}
                </div>
              </ImgContainer>
            </div>
            <div className="right">
              <ProductImageArea className="productInfo-div">
                <h1 className="productTitle BundaySlabBold">
                  {product.description}
                </h1>
                <div style={{ width: "100%", height: "50%" }}>
                  <Image
                    fadeIn={true}
                    fluid={
                      product?.images[2]?.localFile?.childImageSharp?.fluid
                    }
                    alt=""
                  />
                </div>
                <div className="button-area">
                  <Link to="/shop">
                    <Button bg="var(--orange)">

                    Shop Now
                    </Button>
                  </Link>
                  <Link className="where-btn" to="/find-us">
                    Where to Buy
                  </Link>
                </div>
              </ProductImageArea>
            </div>
          </ProductDetail>

          <Related>
            <div className="dotted-Border">
              <p className="related-title">People also bought</p>
            </div>
            <div className="related-products">
              {relatedProducts.map((relatedItem) => {
                return (
                  <RelatedProduct
                    key={relatedItem.handle}
                    product={relatedItem}
                  />
                )
              })}
            </div>
          </Related>
        </Wrapper>
      </BodyWrapper>
      </div>
    </Layout>
  )
}

export default ProductTemplate

const Svg = () => {
  return (
    <>
      <svg width="100%" height="15px">
        <line
          x1="10%"
          x2="90%"
          y1="5"
          y2="5"
          stroke="var(--darkBlue)"
          strokeWidth="5"
          strokeLinecap="round"
          strokeDasharray="1, 20"
        />
      </svg>
    </>
  )
}

export const ImgContainer = styled.div`
  /* width: 500px;
  height: 500px; */
  width: 100%;
  height: 100%;
  .detailImg:hover {
    cursor: zoom-in;
  }
  .thumbnail-container {
    overflow-x: scroll;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    // flex-wrap: wrap;
    @media (max-width: 1024px) {
      display: none;
    }
  }
  @media (max-width: 1024px) {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
`
