import React, { useState } from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"

const RelatedProduct = ({ product }) => {
  const [productImgIndex, setproductImgIndex] = useState(0)
  const [shadow, setshadow] = useState("shadow")

  const changeImg = (bool) => {
    if (bool) {
      setproductImgIndex(1)
      setshadow("")
    } else {
      setproductImgIndex(0)
      setshadow("shadow")
    }
  }
  return (
    <Link
      onMouseOver={() => changeImg(true)}
      onMouseOut={() => changeImg(false)}
      to={`/product/${product.handle}`}
      className="item"
    >
      <Image
        fadeIn={true}
        imgStyle={{
          objectFit: "contain",
        }}
        key={product.handle}
        fluid={product.images[productImgIndex].localFile.childImageSharp.fluid}
        alt={product.title}
        className={`productImg  ${shadow}`}
      />
      <div className="title-container">
        <p>{product.title}</p>
      </div>
    </Link>
  )
}

export default RelatedProduct
