import styled from "styled-components"

const ProductDetail = styled.div`
  margin: 0 auto;
  background-color: var(--beige);
  min-height: 70vh;
  margin: 0 auto;
  // width: 60%;
  color: var(--darkBlue);
  justify-content: space-between;
  display: flex;
  .gatsby-image-wrapper {
    width: 100% !important;
    height: 100% !important;
  }
  .product-with-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 90vw;
    @media (min-width: 1042px) {
      width: 50%;
    }
    @media (max-width: 1024px) {
      margin-bottom: 50px;
    }
  }
  .moreImg {
    display: flex;
    @media (min-width: 1042px) {
      width: 20%;
    }
    width: 40%;
    .button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      cursor: pointer;
    }
  }
  img {
    object-fit: contain !important;
    margin: 0;
  }
  @media (max-width: 1024px) {
    .col {
      margin-top: 50px;
    }
    .productImg-div {
      height: fit-content !important;
      display: flex;
    }
  }
  .col {
    width: 23rem;
    height: 55vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .productInfo-div {
    justify-content: space-between;
    width: 100%;
    @media (min-width: 650px) and (max-width: 1024px) {
      width: 50%;
    }
    min-height: 55vh;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 90vw;
    @media (min-width: 1042px) {
      width: 40%;
    }
  }
  .productImg-div {
    justify-content: space-between;
    width: 100%;
    @media (min-width: 650px) and (max-width: 1024px) {
      width: 50%;
    }
    /* height: 55vh; */
    display: flex;
    align-items: center;
    flex-direction: column;
    &:hover {
      cursor: zoom-in;
    }
  }
  .productImg-div img {
    max-width: 100%;
    max-height: 100%;
    margin-bottom: 0;
  }
  .productTitle {
    text-align: center;
    margin: 0 0 10px 0;
    padding: 0 0 20px 0;
    @media (min-width: 1024px) {
      padding: 0 20px;
    }
    color: var(--darkBlue);
    text-align: center;
    font-size: 2.2rem;
  }
  .productDescription {
    color: var(--darkBlue);
  }
  .productPrice {
    color: var(--darkBlue);
    font-weight: bolder;
    margin: 0;
  }
  .price-div {
    border: none !important;
    display: flex;
    justify-content: center;
    padding: 0 !important;
    display: flex;
    align-items: center;
  }
  .button-div {
    width: 100%;
    // margin: 45px 0;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 4px dotted var(--pink);
    border-left: none;
    border-right: none;
  }
  .button-div div {
    @media (min-width: 1024px) {
      height: 33px;
    }
    width: 30%;
    text-align: center;
    padding: 5px 10px;
    border: 1px solid var(--pink);
    border-radius: 8px;
  }
  button:hover {
    cursor: pointer;
  }
  .add-to-cart {
    border-radius: 8px;
    padding: 3px 5px;
    color: var(--darkBlue);
    width: 40%;
    background-color: var(--pink);
    border: 2px solid var(--darkBlue);
    & button {
      border: none;
      width: 100%;
      background: transparent;
      color: var(--white);
    }
    &:hover {
      border: 1px solid var(--darkBlue);
      background: var(--darkBlue);

      @media (max-width: 769px) {
        background-color: var(--pink);
        border: 0 solid var(--pink);
      }
    }
  }
  .pinkBtn {
    color: var(--white);
    background-color: var(--pink);
    border: none;
    width: 100%;
  }
  p {
    margin: 0;
  }
  .flex-row {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .border-dotted {
    border-bottom: 4px dotted var(--pink);
  }
  .illust {
    /* margin: 10px 0;
     padding: 10px 0; */
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
  @media (max-width: 1024px) {
    // width: 90vw;
    min-height: 100vh;
    margin-bottom: 100px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .col {
      width: 100%;
    }
    .button-div {
      text-align: center;
      flex-direction: column;
      padding: 20px 0;
      // margin: 50px 0;
      min-height: 200px !important;
      text-align: center;
    }
    .price-div {
      display: flex;
      justify-content: center;
    }
    .button-div div {
      width: 100%;
      text-align: center;
      margin: 10px 0;
    }
  }
  @media (max-width: 678px) {
    .productImg-div {
      text-align: center;
      // width: 90%;
      div {
        width: 100% !important;
      }
    }
  }
  @media only screen and (min-device-width: 678px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .productImg-div {
      width: 25rem;
    }
  }
`

const Related = styled.div`
  /* padding-top: 50px; */
  @media (min-width: 1025px) {
    // width: 80vw;
    margin: 0 auto;
    // padding: 0 50px;
  }

  @media (max-width: 1024px) {
    // width: 90vw;
  }

  @media (min-width: 1500px) {
    width: 100%;
  }
  margin: 0 auto;
  .dotted-Border {
    // margin-top: 50px;
    display: flex;
    align-items: center;
    margin-bottom: 5vh;
    & span {
      height: 10px;
      width: 100%;
      margin: 20px 0;
    }
    padding: 25px 0;
    border-bottom: 1px solid var(--darkBlue);
  }
  .related-title {
    margin: 0;
    text-align: center;
    // width: 40%;
    font-size: 30px;
    font-weight: 400;
    text-transform: uppercase;
    color: var(--darkBlue);
    text-align: center;
  }
  div {
    @media (max-width: 768px) {
      flex-direction: column;
    }
    display: flex;
    justify-content: center;
  }
  .related-products {
    align-items: center;
  }
  .item {
    width: 90vw;
    height: 300px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    text-align: center;
    text-decoration: none;
    color: var(--darkBlue);
  }
  .productImg {
    width: 80%;
    height: 70%;
    margin: 0;
  }
  .title-container {
    height: 50px;
    width: 100%;
    margin-bottom: 30px;
    p {
      margin: 0;
    }
  }
  .shadow {
  }
`

export { ProductDetail, Related }
